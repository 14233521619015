import React, { forwardRef, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { IconPropTypes } from '../settings/IconPropTypes'
import { defaultSizes } from '../settings/SizePropTypes'
import { IconLabel } from '../IconLabel/IconLabel'
import { ColorPropTypes } from '../settings/ColorPropTypes'
import { Icon } from '../Icon/Icon'
import useEventListener, { SPACE_KEYS } from '../../hooks/use-event-listener'

/**
 * A button draws attention to important actions with a large selectable surface.
 *
 * USWDS: https://designsystem.digital.gov/components/button/
 *
 */
export const Button = forwardRef(
  (
    {
      active,
      align,
      children,
      className,
      disabled,
      displayClass,
      focus,
      hover,
      href,
      iconAnimation,
      iconName,
      iconOnly,
      iconPosition,
      iconPercent,
      iconSize,
      outline,
      reduced,
      shape,
      size,
      srText,
      textAlign,
      textOnly,
      theme,
      thin,
      to,
      type,
      buttonTextClass,
      ...props
    },
    ref
  ) => {
    const navigate = useNavigate()
    const linkRef = useRef()

    let atts = {}
    atts['className'] = [
      'nac-button',
      /**
       * ACCESSIBILITY: Ensures link touch area meets 508 Success Criterion 2.5.5
       * which specifies a minimum target size of 44px by 44px
       */
      'minh-button',
      'minw-button',
      //
      'bg-transparent',
      'border-0',
      `display-${displayClass}`,
      displayClass === 'inline-flex' &&
      iconOnly &&
      (iconSize == 'xs' || iconSize == '2xs' || iconSize == '3xs')
        ? [
            'margin-bottom-neg-2',
            'margin-left-neg-05',
            'margin-right-neg-05',
            'margin-top-neg-2',
          ].join(' ')
        : '',
      `flex-align-${align}`,
      `flex-justify-${align}`,
      'outline-none',
      'padding-y-05',
      className,
    ].join(' ')
    atts['disabled'] = disabled
    atts['href'] = href
    atts['ref'] = ref
    atts['tabIndex'] = '0'
    if (to)
      atts['to'] = {
        pathname: to.pathname,
        search: to.search,
        hash: to.hash,
      }
    if (to?.state) atts['state'] = to.state

    atts['type'] = type

    /**
     * ACCESSIBILITY: Screen readers handle buttons and links differently.
     * Remember this when styling links to look like buttons. Specifically...
     * ...pressing the Space key triggers a button, but
     * ...pressing the Enter key triggers a link.
     * Here we need to enable both to work in either situation.
     */

    const keypressHandler = (event) => {
      if (!to || linkRef?.current !== event.target) return
      else {
        if (SPACE_KEYS.includes(String(event.code))) {
          event.stopImmediatePropagation()
          event.preventDefault()
          navigate(
            {
              pathname: to.pathname,
              search: to.search,
              hash: to.hash,
            },
            { state: to.state }
          )
        }
      }
    }
    useEventListener('keydown', keypressHandler)

    return (
      /**
       * ACCESSIBILITY: Use standard markup.
       * Avoid using <div> or <img> tags to create buttons, as
       * screen readers don’t automatically know either is a usable button.
       */
      <ButtonWrapper
        wrapper={(children) =>
          to ? (
            <Link {...atts} {...props} ref={linkRef} onClick={keypressHandler}>
              {children}
            </Link>
          ) : href ? (
            <a {...atts} {...props} ref={linkRef} onClick={keypressHandler}>
              {children}
            </a>
          ) : (
            <button {...atts} {...props}>
              {children}
            </button>
          )
        }
      >
        <div
          className={[
            'usa-button',
            'no-print',
            //
            'clickable',
            'flex-align-center',
            `radius-${shape}`,
            active ? 'usa-button--active active' : '',
            disabled ? 'disabled' : '',
            focus ? 'usa-focus focus' : '',
            hover ? 'usa-button--hover hover' : '',
            iconOnly
              ? [
                  'display-inline-flex',
                  'flex-justify-center',
                  'outline-offset-0',
                  !className ? 'width-auto' : '',
                  !outline ? `bg-${theme} ` : '',
                ].join(' ')
              : ['display-flex', 'outline-offset-0', 'width-full'].join(' '),
            reduced ? 'padding-1' : 'padding-105',
            size ? `font-sans-${size}` : '',
            theme ? `usa-button--${theme}` : '',
            textAlign ? `flex-justify-${textAlign}` : '',
            thin ? 'usa-button--thin text-normal' : '',
            outline ? ['usa-button--outline', `border-${theme}`].join(' ') : '',
            textOnly || (iconOnly && outline)
              ? ['box-shadow-0', 'border-0'].join(' ')
              : '',
            outline || textOnly
              ? ['usa-button--link', 'bg-transparent', `text-${theme}`].join(
                  ' '
                )
              : [`theme-${theme}`, `theme-${theme}--text`].join(' '),
            textOnly && outline
              ? [
                  'usa-button--outline-hover',
                  // !active ? 'box-shadow-0 border-0 bg-transparent' : '',
                ].join(' ')
              : '',

            className,
          ].join(' ')}
        >
          {srText && <span className="usa-sr-only">{srText}</span>}
          {iconOnly && iconName ? (
            <Icon
              color={outline || textOnly ? theme : null}
              iconAnimation={iconAnimation}
              iconName={iconName}
              iconPercent={iconPercent}
              iconSize={iconSize ? iconSize : size}
              theme={outline || textOnly ? null : theme}
            />
          ) : !iconOnly && iconName ? (
            <IconLabel
              align={align}
              className="width-full"
              color={
                // disabled && (outline || textOnly)
                //   ? 'base'
                //   :
                textOnly || outline ? theme : null
              }
              iconAnimation={iconAnimation}
              iconName={iconName}
              iconPercent={iconPercent}
              iconPosition={iconPosition}
              iconSize={iconSize ? iconSize : size}
              size={size}
              theme={
                // disabled && !outline && !textOnly
                //   ? 'base'
                //   :
                !textOnly && !outline ? theme : null
              }
            >
              <span
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-justify',
                  textAlign && `text-${textAlign}`,
                  buttonTextClass ? buttonTextClass : '',
                  'width-full',
                ].join(' ')}
              >
                {children}
              </span>
            </IconLabel>
          ) : (
            <>{children}</>
          )}
        </div>
      </ButtonWrapper>
    )
  }
)

const ButtonWrapper = ({ wrapper, children }) => wrapper(children)

Button.defaultProps = {
  active: false,
  align: 'center',
  disabled: false,
  displayClass: 'inline-flex',
  focus: false,
  hover: false,
  iconName: null,
  iconOnly: false,
  iconPosition: 'left',
  iconSize: 'sm',
  outline: false,
  reduced: false,
  shape: 'md',
  size: 'sm',
  srText: '',
  textAlign: 'center',
  textOnly: false,
  thin: false,
  theme: 'primary',
  type: 'button',
}

Button.propTypes = {
  /**
   * Determines if the button is in the active state
   */
  active: PropTypes.bool,
  /**
   * Determines flex alignment
   */
  align: PropTypes.string,
  /**
   * The button text.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Additional classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * Determines if the button is in the disabled state
   */
  disabled: PropTypes.bool,
  /**
   * Specifies the wrapper display class.
   * Used to apply negative margin for inline elements so they don't
   * disrupt the line height of the surrounding elements
   */
  displayClass: PropTypes.oneOf(['inline-flex', 'flex', 'block']),
  /**
   * Determines if the button is in the focus state
   */
  focus: PropTypes.bool,
  /**
   * Determines if the button is in the hover state
   */
  hover: PropTypes.bool,
  /**
   * External link, useful for when you need to "Copy Link Address"
   * Should be used with onClick and event.preventDefault in parent component
   * so that the default actions controlled here are overwritten
   */
  href: PropTypes.string,
  /**
   * Defines the animation to apply to the icon.
   */
  iconAnimation: PropTypes.string,
  /**
   * Defines the icon to use.
   */
  iconName: IconPropTypes,
  /**
   * If true, removes all visible text
   */
  iconOnly: PropTypes.bool,
  /**
   * Defines the background percentage of the icon within its container
   */
  iconPercent: PropTypes.string,
  /**
   * Defines the position of the icon in the button.
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Defines the size of the icon. This is based on a subset of the USWDS size indicators.
   */
  iconSize: PropTypes.oneOf(defaultSizes),
  /**
   * Defines whether the button is displayed as an outline.
   */
  outline: PropTypes.bool,
  /**
   * Determines if the button has less padding
   */
  reduced: PropTypes.bool,
  /**
   * Defines the border radius
   */
  shape: PropTypes.oneOf(['0', 'md', 'pill']),
  /**
   * Defines the size of the button. This is based on a subset of the USWDS size indicators.
   */
  size: PropTypes.oneOf(defaultSizes),
  /**
   * Text to be read by screen readers. Useful for icon only buttons
   */
  srText: PropTypes.string,
  /**
   * The alignment of the text within the button
   */
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  /**
   * Determines if the button has no outline.
   * If this property is combined with the "outline" property, the button will only display an outline when interacted with (i.e. on hover, active, and focus states)
   */
  textOnly: PropTypes.bool,
  /**
   * Defines the color of the button. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  theme: PropTypes.oneOf(ColorPropTypes),
  /**
   * Determines whether the button has a thin outline and normal text or a thick outline and bold text
   */
  thin: PropTypes.bool,
  /**
   * The path and state object used to navigate to a specific page via the React Link component.
   */
  to: PropTypes.object,
  /**
   * The button type
   */
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  /**
   * The button text class
   */
  buttonTextClass: PropTypes.string,
}

Button.displayName = 'Button'
